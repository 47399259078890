<template>
    <v-container>
        <v-card-title class="grey lighten-4 mt-5">
            <v-row dense>
                <v-col cols="12" sm="5"> <b>LISTA DE TRANSACCIONES</b> </v-col>
                <v-col cols="12" sm="1"> </v-col>
                <v-col cols="12" sm="3"> <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details /> </v-col>

                <v-col cols="12" sm="3">
                    <v-btn class="mx-1" small color="primary" dark block @click="downloadHandle">
                        DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text class="white pa-2">
            <v-row dense>
                <v-col cols="12">
                    <v-data-table
                        multi-sort
                        :search="search"
                        :headers="headers"
                        :items="_listTransaction"
                        :loading="loading2"
                        class="elevation-1"
                        :items-per-page="20"
                    >
                        <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" color="green lighten-2" small @click="seeDescription(item)">mdi-eye</v-icon>
                                </template>
                                <span>VER DETALLES</span>
                            </v-tooltip>
                            <!-- <v-icon small @click="editItem(item)" color="green">mdi-pencil</v-icon> -->

                            <!-- <v-icon small @click="deleteItem(item)" color="primary">mdi-delete</v-icon> -->
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
        <v-dialog v-model="dialog" width="400">
            <v-card>
                <v-card-text>
                    <v-row class="mt-5">
                        <v-col cols="12"> <b>TIPO :</b> {{ itemSelected?.types }}</v-col>
                        <v-col cols="12"> <b>AlMACEN :</b> {{ itemSelected?.warehouse }}</v-col>
                        <v-col cols="12"> <b>Guia :</b> {{ itemSelected?.numGuide }}</v-col>
                        <v-col cols="12"> <b>Ticket :</b> {{ itemSelected?.numTicket }}</v-col>
                        <v-col cols="12"> <b>Orden Trabajo :</b> {{ itemSelected?.oT }}</v-col>

                        <v-col cols="12"> <b>Observaciones :</b> {{ itemSelected?.observations }}</v-col>
                        <v-col cols="12"> <b>CATEGORIAS :</b> {{ itemSelected?.categories }}</v-col>
                        <v-col cols="12"> <b>PRODUCTOS :</b> {{ itemSelected?.detalle_producto }}</v-col>
                        <v-col cols="12"> <b>created_at :</b> {{ itemSelected?.created_at }}</v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="dialog = false">CERRAR</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import { exportXLSX } from '@/utils/genericUtils'

export default {
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            hourRules: [(v) => !!v || 'Hora es requerido', (v) => /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Formato de hora inválido'],
            itemSelected: null,
            dialog: false,
            loading: false,
            search: null,
            loading2: false,
            headers: [
                { text: 'Actions', value: 'action', sortable: false },
                { text: 'id', value: 'id' },
                { text: 'Almacen', value: 'warehouse' },
                { text: 'Tipo', value: 'types' },
                //{ text: 'GUIA', value: 'numGuide' },
                { text: 'CORRELATIVO', value: 'correlative' },
                { text: 'TICKET', value: 'numTicket' },
                { text: 'INTERNAL_ID', value: '_code' },
                { text: 'Nombre Produc', value: '_name' },
                { text: 'Cantidad', value: '_quantity' },

                { text: 'CATEGORIAS', value: 'categories' },
                { text: 'OT', value: 'oT' },

                { text: 'OBSERVACIONES', value: 'observations' },

                { text: 'FECHA OPERACION', value: 'operation_date' },
                { text: 'FECHA CREACION', value: 'created_at' },

                { text: 'AREA', value: 'numArea' },
                { text: 'Proyecto', value: 'project' },
                { text: 'Autorizado', value: 'nameAuthorized' },
                { text: 'Recepción', value: 'nameReception' }
            ]
        }
    },
    computed: {
        ...mapState('users', ['user']),
        ...mapState('inventario', ['listTransaction']),
        ...mapState('sedes', ['custom_warehouses']),
        _listTransaction() {
            let newItems = []
            this.listTransaction.map((x) => {
                const isFind = this.custom_warehouses.find((y) => y.id == x.warehouse_id)
                const items = JSON.parse(x.products)
                for (const iterator of items) {
                    newItems.push({
                        ...x,
                        warehouse: isFind.description || '',
                        _code: iterator.internal_id,
                        _name: iterator.name,
                        _quantity: iterator.quantity
                    })
                }
            })
            return newItems
        }
    },
    methods: {
        ...mapActions({
            getCustomWarehouses: 'sedes/getCustomWarehouses'
        }),
        seeDescription(item) {
            this.dialog = true
            this.itemSelected = item
        },

        ...mapActions({
            getItemAddInventory: 'inventario/getItemAddInventory'
        }),
        ...mapMutations({
            /*             RESET_FORM: 'clientes/RESET_FORM',
            UPDATE_DATOS: 'clientes/UPDATE_DATOS', */
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),
        convertirArray(data) {
            const _data = JSON.parse(data)
            console.log('data///', data)
            const resultado = _data
                .map((x) => ({
                    internal_id: `## COD: ${x.internal_id}`,
                    name: `Nombre: ${x.name}`,
                    quantity: `UNI: ${x.quantity}`
                }))
                .map((item) => Object.values(item).join(' | '))
                .join('\n')

            return resultado
        },
        downloadHandle() {
            this.loading = true
            const headers = [
                'id',
                'types',
                'oT',
                'warehouse',
                'warehouse_id',
                'numGuide',
                'numTicket',
                'categories',
                'detalle_producto',
                'observations',
                'numArea',
                'project',
                'nameAuthorized',
                'nameReception',
                'operation_date',
                'created_at'
            ]
            const filename = `REPORT_TRANSACTION_INVENTARY_${Date.now()}`

            exportXLSX({ headers, campos: headers, arrayData: this._listTransaction }, { filename })
            this.loading = false
        }
    },

    async created() {
        this.loading2 = true
        this.getCustomWarehouses()

        await this.getItemAddInventory()

        this.loading2 = false
    }
}
</script>
